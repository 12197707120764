import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import * as actions from './actions';
import * as actionStatus from '../OrderPage/actions';
import { OrdersService } from './service';
import {
  OrderResponseParam,
  OrdersChangeStatusParams,
  OrdersResponseParam,
  StatusResponse,
  TestOrdersRes,
  UserRole,
  ProductsResponseCache,
  PaymentsResponseCache,
  createClaimParams,
  cancelClaimParams,
  coordinatesParams,
  AllNetworkEnabledParam,
  OrderHistoryResponse,
} from './types';
import {
  mapDataForOrderPageCache,
  mapDataForOrdersPageTable,
  mappedDataForOrdersSearch,
  mappedHistoryEvents,
} from './helpers';
import * as actionsNotification from '../InitComponent/actions';
import {
  NOTIFICATION_CHANGES_NOT_SAVED,
  NOTIFICATION_CHANGES_SAVED,
  PHARMACY_ISSUED,
} from '../../global/constants';
import { OrderService } from '../OrderPage/service';
import { getFromLocalStorage } from '../../global/helpers';
import { ErrorType, OrderStatusesNewResponse } from '../OrderPage/types';
import { mappedDataForSources } from '../SourcesPage/helpers';
import { SourceResponse } from '../SourcesPage/types';
import { StatusType } from '../../global/types';
import { selectors as selectorsOrder } from '../OrderPage/reducer';
import { SourceService } from '../SourcePage/service';
import { PharmacyTag } from '../SourcePage/types';

interface StatusChangeSagasParams {
  type: string;
  payload: { id: string; token: string; postEffect: any };
}

interface StatusChangePaymentParams {
  type: string;
  payload: {
    uuid: string;
    data: {
      comment: string;
      status: string;
      type: string;
    };
    postEffect: any;
  };
}
interface RepaymentParams {
  type: string;
  payload: {
    id: string;
    data: {
      amount: number;
      comment: string;
      payment_type: string;
    };
    postEffect: any;
  };
}
interface RefundParams {
  type: string;
  payload: {
    id: string;
    data: {
      amount: number;
      comment: string;
    };
    postEffect: any;
  };
}
interface CancelPaymentParams {
  type: string;
  payload: {
    id: string;
    data: {
      payment_uuid: string;
      comment: string;
    };
    postEffect: any;
  };
}
interface StatusSagasParams {
  type: string;
  payload: {
    id: string;
    token: string;
    postEffect: any;
    status: string;
    errorEffect: () => void;
  };
}

interface CancelOrderSagaParams {
  type: string;
  payload: { id: string; message: string; token: string; postEffect: any };
}

interface GetSearchOrderParams {
  type: string;
  payload: { searchValue: string; token: string; testOrders?: boolean };
}

interface GetNotificationsParams {
  type: string;
  payload: { testOrders: boolean };
}

interface GetOrderParams {
  type: string;
  payload: {
    role: string;
    id?: string;
    token?: string;
    status: string;
    payment?: boolean;
    testOrders?: boolean;
    source?: string;
    paymentType?: string;
    delivery?: string;
    limit?: string;
    postEffect: any;
    errorEffect: () => void;
  };
}

interface GetScheduleParams {
  type: string;
  payload: {
    sourceCode: string;
  };
}

interface PutSeenOrderParam {
  type: string;
  payload: {
    orderData: any;
    postEffect: any;
  };
}

interface createClaimParam {
  type: string;
  payload: {
    data: any;
    postEffect: any;
  };
}

interface coordinatesParam {
  type: string;
  payload: {
    data: any;
    postEffect: any;
  };
}
interface cancelClaimParam {
  type: string;
  payload: {
    claimId: any;
    data: any;
    postEffect: any;
  };
}
interface NetworkParam {
  type: string;
  payload: { networkCode: string; operator?: boolean; postEffect: any };
}

interface TestOrdersParams {
  type: string;
  payload: { phone: string; source: string; postEffect: any };
}

interface CollectorManagerParam {
  type: string;
  payload: { isCollector: boolean; sourceCode: string; postEffect: any };
}

interface TestOrdersResponse {
  status: string;
  result: TestOrdersRes[] | null;
}

interface GetSourcesResponse {
  status: string;
  result: SourceResponse[] | null;
}

interface StatusMappedResponse {
  code: string;
  color: string;
  name: string;
  value: number;
  available: boolean;
}

interface GetOrderHistoryParams {
  type: string;
  payload: { orderId: string; createdAt: string; token: string };
}

export function* getOrdersSaga({ payload }: GetOrderParams) {
  try {
    const response: OrdersResponseParam = yield call(
      OrdersService.getOrders,
      payload.status,
      payload.payment,
      payload.source,
      payload.paymentType,
      payload.delivery,
      payload.testOrders,
      payload.limit
    );

    const reduxStatus: StatusMappedResponse[] = yield select(
      selectorsOrder.allStatuses
    );

    const reduxStatusDelivery: StatusMappedResponse[] = yield select(
      selectorsOrder.deliveryStatuses
    );

    const reduxStatusPharmacy: StatusMappedResponse[] = yield select(
      selectorsOrder.allPharmacyStatuses
    );

    let statuses: OrderStatusesNewResponse = { result: [] } as any;
    let statusesPharmacy: StatusResponse = { result: [] } as any;

    if (!reduxStatus) {
      statuses = yield call(
        // @ts-ignore
        OrderService.getAllStatuses,
        payload.token
      );

      yield put(actionStatus.getAllStatuses.success(statuses.result));
    }

    if (!reduxStatusPharmacy) {
      statusesPharmacy = yield call(
        // @ts-ignore
        OrderService.getAllStatusesPharmacy,
        payload.token
      );

      yield put(
        actionStatus.getAllPharmacyStatuses.success(statusesPharmacy.result)
      );
    }

    const mappedData = mapDataForOrdersPageTable(
      response.result,
      [],
      [],
      reduxStatus || statuses?.result.internal_statuses,
      reduxStatusPharmacy || statusesPharmacy?.result.internal_statuses,
      reduxStatusDelivery || statuses?.result.claim_statuses
    );
    yield put(actions.getOrders.success(mappedData));
    if (payload.postEffect) {
      yield call(payload.postEffect);
    }
  } catch (error) {
    yield put(actions.getOrders.error(error));
  }
}

export function* getAllOrdersSaga({ payload }: GetOrderParams) {
  try {
    const response: OrdersResponseParam = yield call(
      OrdersService.getAllOrders,
      payload.role
    );
    const mappedData = mapDataForOrdersPageTable(response.result, []);
    yield put(actions.getOrders.success(mappedData));
  } catch (error) {
    yield put(actions.getOrders.error(error));
  }
}

export function* getScheduleSaga({ payload }: GetScheduleParams) {
  try {
    const response: OrdersResponseParam = yield call(
      OrdersService.getSchedule,
      payload.sourceCode
    );

    yield put(actions.getSchedule.success(response.result));
  } catch (error) {
    yield put(actions.getSchedule.error(error));
  }
}

export function* getAllNetworkEnabledSaga() {
  try {
    const response: AllNetworkEnabledParam = yield call(
      OrdersService.getAllNetworkEnabled
    );

    yield put(actions.getAllNetworkEnabled.success(response.networks));
  } catch (error) {
    yield put(actions.getAllNetworkEnabled.error(error));
  }
}

export function* getOrderSaga({ payload }: GetOrderParams) {
  try {
    const response: OrderResponseParam = yield call(
      OrdersService.getOrderById,
      String(payload.id),
      payload.testOrders
    );

    const tagsResponse: { result: PharmacyTag[] } = yield call(
      SourceService.getPharmacyTags,
      'source'
    );

    const networkTagsResponse: { result: PharmacyTag[] } = yield call(
      SourceService.getPharmacyTags,
      'network'
    );

    const reduxStatus: StatusMappedResponse[] = yield select(
      selectorsOrder.allStatuses
    );
    const reduxStatusPharmacy: StatusMappedResponse[] = yield select(
      selectorsOrder.allPharmacyStatuses
    );

    let statuses: OrderStatusesNewResponse = { result: [] } as any;
    let statusesPharmacy: StatusResponse = { result: [] } as any;

    if (!reduxStatus) {
      statuses = yield call(
        // @ts-ignore
        OrderService.getAllStatuses,
        payload.token
      );

      yield put(actionStatus.getAllStatuses.success(statuses.result));
    }

    if (!reduxStatusPharmacy) {
      statusesPharmacy = yield call(
        // @ts-ignore
        OrderService.getAllStatusesPharmacy,
        payload.token
      );

      yield put(
        actionStatus.getAllPharmacyStatuses.success(statusesPharmacy.result)
      );
    }

    const currentOrder = response.result;

    const productsSku = [
      // @ts-ignore
      ...new Set(
        [currentOrder]?.reduce((accum: any, item: any) => {
          const itemProductsSku = item?.order.items.map(
            (product: any) => product.sku
          );
          return [...accum, ...itemProductsSku];
        }, [])
      ),
    ];

    const analogSku = [
      // @ts-ignore
      ...new Set(
        [currentOrder]?.reduce((accum: any, item: any) => {
          const itemProductsSku = item?.order.items.map((product: any) =>
            product.proposal ? product.proposal?.sku : ''
          );
          return [...accum, ...itemProductsSku];
        }, [])
      ),
    ].filter((el) => el !== '' && el);

    const productsWareId = [
      // @ts-ignore
      ...new Set(
        [currentOrder]?.reduce((accum: any, item: any) => {
          const itemProductsSku = item?.order.items.map(
            (product: any) => product.ware_id
          );
          return [...accum, ...itemProductsSku];
        }, [])
      ),
    ].filter((el) => el !== '' && el);

    const analogWareId = [
      // @ts-ignore
      ...new Set(
        [currentOrder]?.reduce((accum: any, item: any) => {
          const itemProductsSku = item?.order.items.map((product: any) =>
            product.proposal ? product.proposal?.ware_id : ''
          );
          return [...accum, ...itemProductsSku];
        }, [])
      ),
    ].filter((el) => el !== '' && el);

    const wareIds =
      // eslint-disable-next-line
      productsWareId.length !== 0
        ? productsWareId.concat(analogWareId)
        : // eslint-disable-next-line
        productsWareId.length === 0
        ? analogWareId
        : analogWareId.length === 1
        ? productsWareId
        : [];

    const skus =
      // eslint-disable-next-line
      productsSku.length !== 0
        ? productsSku.concat(analogSku)
        : // eslint-disable-next-line
        productsSku.length === 0
        ? analogSku
        : analogSku.length === 1
        ? productsSku
        : [];

    const checkIsEmptyWareId = (wareIds: number[]) => {
      if (wareIds?.length === 0) {
        return true;
      }

      let isEmpty = false;
      wareIds.forEach((wareId) => {
        if (!wareId) isEmpty = true;
      });
      return isEmpty;
    };

    // @ts-ignore
    const responsePriceQuantity = yield call(
      // @ts-ignore
      OrderService.getPriceWithQuantityBySource,
      currentOrder?.order.source,
      currentOrder?.order.order_number,
      checkIsEmptyWareId(wareIds) ? skus : wareIds,
      !checkIsEmptyWareId(wareIds)
    );
    const productsResponse: ProductsResponseCache = yield call(
      OrdersService.getProductsBySkus,
      productsSku
    );

    let analogResponse: ProductsResponseCache = { result: [] };
    if (analogSku.length !== 0) {
      analogResponse = yield call(OrdersService.getProductsBySkus, analogSku);
    }

    const paymentsResponse: PaymentsResponseCache = yield call(
      OrdersService.getOrderPaymentsById,
      String(payload.id)
    );
    const mappedData = mapDataForOrderPageCache(
      [response.result],
      productsResponse.result,
      wareIds,
      analogResponse.result,
      responsePriceQuantity.result,
      reduxStatus || statuses?.result.internal_statuses,
      reduxStatusPharmacy || statusesPharmacy?.result.internal_statuses,
      tagsResponse.result,
      networkTagsResponse.result,
      paymentsResponse.result
    );

    const order = mappedData.find((item) => item.id === payload.id);

    yield put(actions.setSelectedOrder.success(order));

    if (payload.postEffect) {
      yield call(payload.postEffect, order);
    }
  } catch (error) {
    if (payload.errorEffect) {
      payload.errorEffect();
    }
    throw error;
  }
}

export function* getOrdersRefreshSaga({ payload }: GetOrderParams) {
  try {
    const response: OrdersResponseParam = yield call(
      OrdersService.getOrders,
      payload.status,
      payload.payment,
      payload.source,
      payload.paymentType,
      payload.delivery,
      payload.testOrders,
      payload.limit
    );
    const reduxStatus: StatusMappedResponse[] = yield select(
      selectorsOrder.allStatuses
    );
    const reduxStatusPharmacy: StatusMappedResponse[] = yield select(
      selectorsOrder.allPharmacyStatuses
    );
    const reduxStatusDelivery: StatusMappedResponse[] = yield select(
      selectorsOrder.deliveryStatuses
    );

    let statuses: OrderStatusesNewResponse = { result: [] } as any;
    let statusesPharmacy: StatusResponse = { result: [] } as any;

    if (!reduxStatus) {
      statuses = yield call(
        // @ts-ignore
        OrderService.getAllStatuses,
        payload.token
      );

      yield put(actionStatus.getAllStatuses.success(statuses.result));
    }

    if (!reduxStatusPharmacy) {
      statusesPharmacy = yield call(
        // @ts-ignore
        OrderService.getAllStatusesPharmacy,
        payload.token
      );

      yield put(
        actionStatus.getAllPharmacyStatuses.success(statusesPharmacy.result)
      );
    }

    if (
      payload.status === StatusType.NEW &&
      getFromLocalStorage('role') === UserRole.PHARMACIST
    ) {
      const responseOrder: OrdersResponseParam = yield call(
        OrdersService.getOrders,
        StatusType.IN_PHARMACY_PLACED
      );
      const mappedData = mapDataForOrdersPageTable(
        response.result.concat(responseOrder.result),
        [],
        [],
        reduxStatus || statuses?.result.internal_statuses,
        reduxStatusPharmacy || statusesPharmacy?.result.internal_statuses,
        reduxStatusDelivery || statuses?.result.claim_statuses
      );

      yield put(actions.getOrdersRefresh.success(mappedData));
    } else {
      const mappedData = mapDataForOrdersPageTable(
        response.result,
        [],
        [],
        reduxStatus || statuses?.result.internal_statuses,
        reduxStatusPharmacy || statusesPharmacy?.result.internal_statuses,
        reduxStatusDelivery || statuses?.result.claim_statuses
      );
      yield put(actions.getOrdersRefresh.success(mappedData));
    }
  } catch (error) {
    yield put(actions.getOrdersRefresh.error(error));
  }
}

export function* getOrdersSearch({ payload }: GetSearchOrderParams) {
  try {
    const response: OrdersResponseParam = yield call(
      OrdersService.getOrderSearch,
      payload.searchValue,
      payload.testOrders
    );

    const reduxStatus: StatusMappedResponse[] = yield select(
      selectorsOrder.allStatuses
    );
    const reduxStatusPharmacy: StatusMappedResponse[] = yield select(
      selectorsOrder.allPharmacyStatuses
    );

    let statuses: OrderStatusesNewResponse = { result: [] } as any;
    let statusesPharmacy: StatusResponse = { result: [] } as any;

    if (!reduxStatus) {
      statuses = yield call(
        // @ts-ignore
        OrderService.getAllStatuses,
        payload.token
      );

      yield put(actionStatus.getAllStatuses.success(statuses.result));
    }

    if (!reduxStatusPharmacy) {
      statusesPharmacy = yield call(
        // @ts-ignore
        OrderService.getAllStatusesPharmacy,
        payload.token
      );

      yield put(
        actionStatus.getAllPharmacyStatuses.success(statusesPharmacy.result)
      );
    }

    const mappedData = mappedDataForOrdersSearch(
      // @ts-ignore
      response.result,
      [],
      reduxStatus || statuses?.result.internal_statuses,
      reduxStatusPharmacy || statusesPharmacy?.result.internal_statuses
    );

    yield put(actions.getOrdersSearch.success(mappedData));
  } catch (error) {
    yield put(actions.getOrdersSearch.error(error));
  }
}

export function* cancelOrderSaga({ payload }: CancelOrderSagaParams) {
  try {
    const response: OrdersChangeStatusParams = yield call(
      OrdersService.cancelOrder,
      payload.token,
      payload.id,
      payload.message
    );
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );
    if (payload.postEffect) {
      yield call(payload.postEffect);
    }
    yield put(actions.getOrdersRefresh.request());
    yield put(actions.cancelOrder.success(response));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.cancelOrder.error(error));
  }
}

export function* cancelPromocodeSaga({ payload }: CancelOrderSagaParams) {
  try {
    const response: OrdersChangeStatusParams = yield call(
      OrdersService.cancelPromocode,
      payload.token,
      payload.id
    );

    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );

    if (payload.postEffect) {
      yield call(payload.postEffect);
    }
    yield put(actions.getOrdersRefresh.request());
    yield put(actions.cancelPromocode.success(response));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.cancelPromocode.error(error));
  }
}

export function* putCollectingStatusSaga({ payload }: StatusChangeSagasParams) {
  try {
    const response: OrdersChangeStatusParams = yield call(
      OrdersService.changeOrderStatus,
      payload.token,
      payload.id,
      StatusType.IN_PHARMACY_COLLECTING,
      ''
    );
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );
    if (payload.postEffect) {
      yield call(payload.postEffect);
    }
    yield put(actions.getOrdersRefresh.request());
    yield put(actions.putCollectingStatus.success(response));
  } catch (error) {
    // @ts-ignore
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.putCollectingStatus.error(error));
  }
}

export function* putPaymentStatusSaga({ payload }: StatusChangePaymentParams) {
  try {
    const response: OrdersChangeStatusParams = yield call(
      OrdersService.changePaymentsStatus,
      payload.uuid,
      payload.data
    );
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );
    yield put(actions.putPaymentStatus.success(response));
    if (payload.postEffect) {
      yield call(payload.postEffect);
    }
  } catch (error: any) {
    let errorText;
    if (error?.response?.data?.code === 'O400106') {
      errorText = 'Не было оплаты';
    } else if (error?.response?.data?.code === 'O400139') {
      errorText = 'Невозможно применить действие. Платеж закрыт';
    } else {
      errorText = NOTIFICATION_CHANGES_NOT_SAVED;
    }
    yield put(
      actionsNotification.addApplicationNotification({
        content: errorText,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.putPaymentStatus.error(error));
  }
}

export function* postRepaymentSaga({ payload }: RepaymentParams) {
  try {
    // @ts-ignore
    const response: any = yield call(
      OrdersService.postRepayment,
      payload.id,
      payload.data
    );
    yield put(actions.postRepayment.success(response));
    if (payload.postEffect) {
      yield call(payload.postEffect);
    }
  } catch (error: any) {
    yield put(actions.postRepayment.error(error));
  }
}

export function* postRefundSaga({ payload }: RefundParams) {
  try {
    // @ts-ignore
    const response: any = yield call(
      OrdersService.postRefund,
      payload.id,
      payload.data
    );
    yield put(actions.postRefund.success(response));
    if (payload.postEffect) {
      yield call(payload.postEffect);
    }
  } catch (error: any) {
    let errorText;
    if (error?.response?.data?.code === 'O400137') {
      errorText = 'Сумма возвратов превышает сумму платежа!';
    } else if (error?.response?.data?.code === 'O400136') {
      errorText = 'Уже есть действующий возврат!';
    } else if (error?.response?.data?.code === 'O400135') {
      errorText = 'Платеж не оплачен!';
    } else {
      errorText = NOTIFICATION_CHANGES_NOT_SAVED;
    }
    yield put(
      actionsNotification.addApplicationNotification({
        content: errorText,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.postRefund.error(error));
  }
}

export function* postCancelPaymentSaga({ payload }: CancelPaymentParams) {
  try {
    // @ts-ignore
    const response: any = yield call(
      OrdersService.postCancelPayment,
      payload.id,
      payload.data
    );
    yield put(actions.postCancelPayment.success(response));
    if (payload.postEffect) {
      yield call(payload.postEffect);
    }
  } catch (error: any) {
    const errorText = 'Ошибка отмены платежа!';
    yield put(
      actionsNotification.addApplicationNotification({
        content: errorText,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.postCancelPayment.error(error));
  }
}

export function* putForAdjustmentSaga({ payload }: StatusChangeSagasParams) {
  try {
    const response: OrdersChangeStatusParams = yield call(
      OrdersService.changeOrderStatus,
      payload.token,
      payload.id,
      StatusType.FOR_ADJUSTMENT,
      ''
    );

    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );

    if (payload.postEffect) {
      yield call(payload.postEffect);
    }

    yield put(actions.getOrdersRefresh.request());
    yield put(actions.putForAdjustmentStatus.success(response));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.putForAdjustmentStatus.error(error));
  }
}

export function* putNoReceiptSaga({ payload }: StatusChangeSagasParams) {
  try {
    const response: OrdersChangeStatusParams = yield call(
      OrdersService.changeOrderStatus,
      payload.token,
      payload.id,
      StatusType.NO_RECEIPT,
      ''
    );

    if (payload.postEffect) {
      yield call(payload.postEffect);
    }

    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );

    if (payload.postEffect) {
      yield call(payload.postEffect);
    }

    yield put(actions.getOrdersRefresh.request());
    yield put(actions.putNoReceiptStatus.success(response));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.putNoReceiptStatus.error(error));
  }
}

export function* putWaitingReceiptSaga({ payload }: StatusChangeSagasParams) {
  try {
    const response: OrdersChangeStatusParams = yield call(
      OrdersService.changeOrderStatus,
      payload.token,
      payload.id,
      StatusType.WAITING_RECEIPT,
      ''
    );

    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );

    if (payload.postEffect) {
      yield call(payload.postEffect);
    }

    yield put(actions.getOrdersRefresh.request());
    yield put(actions.putWaitingReceiptStatus.success(response));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.putWaitingReceiptStatus.error(error));
  }
}

export function* putReadyStatusSaga({ payload }: StatusChangeSagasParams) {
  try {
    const response: OrdersChangeStatusParams = yield call(
      OrdersService.changeOrderStatus,
      payload.token,
      payload.id,
      StatusType.IN_PHARMACY_COLLECTED,
      ''
    );
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );
    if (payload.postEffect) {
      yield call(payload.postEffect);
    }
    yield put(actions.getOrdersRefresh.request());
    yield put(actions.putReadyStatus.success(response));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.putReadyStatus.error(error));
  }
}

export function* putCompletedStatus({ payload }: StatusChangeSagasParams) {
  try {
    const response: OrdersChangeStatusParams = yield call(
      OrdersService.changeOrderStatus,
      payload.token,
      payload.id,
      StatusType.IN_PHARMACY_ISSUED,
      ''
    );
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );
    if (payload.postEffect) {
      yield call(payload.postEffect);
    }
    yield put(actions.getOrdersRefresh.request());
    yield put(actions.putCompletedStatus.success(response));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.putCompletedStatus.error(error));
  }
}

export function* putClarificationStatus({ payload }: StatusChangeSagasParams) {
  try {
    const response: OrdersChangeStatusParams = yield call(
      OrdersService.changeOrderStatus,
      payload.token,
      payload.id,
      StatusType.ON_CLARIFICATION,
      ''
    );
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );
    if (payload.postEffect) {
      yield call(payload.postEffect);
    }
    yield put(actions.putClarificationStatus.success(response));
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.putClarificationStatus.error(error));
  }
}

export function* putStatus({ payload }: StatusSagasParams) {
  try {
    const response: OrdersChangeStatusParams = yield call(
      OrdersService.changeOrderStatus,
      payload.token,
      payload.id,
      payload.status,
      ''
    );
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );
    if (payload.postEffect) {
      yield call(payload.postEffect);
    }

    yield put(actions.putStatus.success(response));
  } catch (error) {
    if (payload.errorEffect) {
      payload.errorEffect();
    }

    const errorMessage =
      // @ts-ignore
      error?.response?.data?.error === ErrorType.EXPECTED_IN_PHARMACY_ISSUED
        ? PHARMACY_ISSUED
        : NOTIFICATION_CHANGES_NOT_SAVED;
    yield put(
      actionsNotification.addApplicationNotification({
        content:
          // @ts-ignore
          error?.response?.data?.code === 'G400018'
            ? 'Вызовите курьера через яндекс'
            : errorMessage,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.putStatus.error(error));
  }
}

export function* putSeenOrdersSaga({ payload }: PutSeenOrderParam) {
  try {
    const response: OrdersChangeStatusParams = yield call(
      OrdersService.putSeenOrders,
      payload.orderData
    );

    if (payload.postEffect) {
      yield call(payload.postEffect);
    }

    yield put(actions.putSeenOrders.success(response));
  } catch (e) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.putSeenOrders.error(e));
  }
}

export function* createClaimSaga({ payload }: createClaimParam) {
  try {
    const response: createClaimParams = yield call(
      OrdersService.createClaim,
      payload.data
    );

    if (payload.postEffect) {
      yield call(payload.postEffect);
    }

    yield put(actions.createClaim.success(response));
  } catch (e) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.createClaim.error(e));
  }
}

export function* coordinatesSaga({ payload }: coordinatesParam) {
  try {
    const response: coordinatesParams = yield call(
      OrdersService.coordinates,
      payload.data
    );

    if (payload.postEffect) {
      yield call(payload.postEffect);
    }

    yield put(actions.coordinates.success(response));
  } catch (e) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.coordinates.error(e));
  }
}

export function* cancelClaimSaga({ payload }: cancelClaimParam) {
  try {
    const response: cancelClaimParams = yield call(
      OrdersService.cancelClaim,
      payload.claimId,
      payload.data
    );

    if (payload.postEffect) {
      yield call(payload.postEffect);
    }

    yield put(actions.cancelClaim.success(response));
    yield put(actions.createClaim.success(null));
  } catch (e) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.cancelClaim.error(e));
  }
}

export function* putMutedOrdersSaga({ payload }: PutSeenOrderParam) {
  try {
    const response: OrdersChangeStatusParams = yield call(
      OrdersService.putMutedOrders,
      payload.orderData
    );

    if (payload.postEffect) {
      yield call(payload.postEffect);
    }

    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );

    yield put(actions.putMutedOrders.success(response));
  } catch (e) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.putMutedOrders.error(e));
  }
}

export function* getNotMutedOrdersSaga({ payload }: GetNotificationsParams) {
  try {
    const response: { result: any; status: string } = yield call(
      OrdersService.getNotMuted,
      payload.testOrders
    );

    yield put(actions.getNotMutedOrders.success(response.result));
  } catch (e) {
    yield put(actions.putMutedOrders.error(e));
  }
}

export function* getNotViewedOrdersSaga({ payload }: GetNotificationsParams) {
  try {
    const response: { result: any; status: string } = yield call(
      OrdersService.getNotViewed,
      payload.testOrders
    );

    yield put(actions.getNotViewedOrders.success(response.result));
  } catch (e) {
    yield put(actions.getNotViewedOrders.error(e));
  }
}

export function* getSourcesByNetworkSaga({ payload }: NetworkParam) {
  try {
    const response: GetSourcesResponse = payload.operator
      ? yield call(OrdersService.getAllSources)
      : yield call(OrdersService.getSourcesByNetwork, payload.networkCode);
    const mappedNetwork = mappedDataForSources(response.result);

    if (payload.postEffect) {
      if (mappedNetwork?.length === 1) {
        payload.postEffect(mappedNetwork[0]);
      }
    }
    yield put(actions.getSourcesByNetwork.success(mappedNetwork));
  } catch (error) {
    yield put(actions.getSourcesByNetwork.error(error));
  }
}

export function* generateTestOrdersSaga({ payload }: TestOrdersParams) {
  try {
    const response: TestOrdersResponse = yield call(
      OrdersService.generateTestOrders,
      payload.phone,
      payload.source
    );

    if (payload.postEffect) {
      yield call(payload.postEffect);
    }

    yield put(actions.generateTestOrders.success(response.result));
  } catch (error) {
    yield put(actions.generateTestOrders.error(error));
  }
}

export function* postCollectorManagerSaga({ payload }: CollectorManagerParam) {
  try {
    // @ts-ignore
    const response: any = yield call(
      OrdersService.postCollectorManager,
      payload.isCollector,
      payload.sourceCode
    );

    yield put(actions.postCollectorManager.success(response.result));
    if (payload.postEffect) {
      yield call(payload.postEffect);
    }

    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_SAVED,
        canBeClosed: true,
        type: 'success',
      })
    );
  } catch (error) {
    yield put(
      actionsNotification.addApplicationNotification({
        content: NOTIFICATION_CHANGES_NOT_SAVED,
        canBeClosed: true,
        type: 'error',
      })
    );
    yield put(actions.postCollectorManager.error(error));
  }
}

export function* getCartHistorySaga({ payload }: GetOrderHistoryParams) {
  try {
    const response: OrderHistoryResponse = yield call(
      OrdersService.getCartHistory,
      payload.orderId,
      payload.createdAt
    );
    const mappedData = mappedHistoryEvents(response.result.items);

    yield put(actions.getCartHistory.success(mappedData));
  } catch (error) {
    yield put(actions.getCartHistory.error(error));
  }
}
export function* ordersPageWatcherSaga() {
  yield all([
    takeLatest(actions.getOrders.REQUEST, getOrdersSaga),
    takeLatest(actions.getSchedule.REQUEST, getScheduleSaga),
    takeLatest(actions.getALLOrders.REQUEST, getAllOrdersSaga),
    takeLatest(actions.setSelectedOrder.REQUEST, getOrderSaga),
    takeLatest(actions.getOrdersRefresh.REQUEST, getOrdersRefreshSaga),
    takeLatest(actions.cancelOrder.REQUEST, cancelOrderSaga),
    takeLatest(actions.cancelPromocode.REQUEST, cancelPromocodeSaga),
    takeLatest(actions.putCollectingStatus.REQUEST, putCollectingStatusSaga),
    takeLatest(actions.putReadyStatus.REQUEST, putReadyStatusSaga),
    takeLatest(actions.putCompletedStatus.REQUEST, putCompletedStatus),
    takeLatest(actions.putClarificationStatus.REQUEST, putClarificationStatus),
    takeLatest(actions.putStatus.REQUEST, putStatus),
    takeLatest(actions.getOrdersSearch.REQUEST, getOrdersSearch),
    takeLatest(actions.putSeenOrders.REQUEST, putSeenOrdersSaga),
    takeLatest(actions.putMutedOrders.REQUEST, putMutedOrdersSaga),
    takeLatest(actions.getNotMutedOrders.REQUEST, getNotMutedOrdersSaga),
    takeLatest(actions.getNotViewedOrders.REQUEST, getNotViewedOrdersSaga),
    takeLatest(actions.getSourcesByNetwork.REQUEST, getSourcesByNetworkSaga),
    takeLatest(actions.generateTestOrders.REQUEST, generateTestOrdersSaga),
    takeLatest(actions.postCollectorManager.REQUEST, postCollectorManagerSaga),
    takeLatest(actions.putWaitingReceiptStatus.REQUEST, putWaitingReceiptSaga),
    takeLatest(actions.putNoReceiptStatus.REQUEST, putNoReceiptSaga),
    takeLatest(actions.putForAdjustmentStatus.REQUEST, putForAdjustmentSaga),
    takeLatest(actions.createClaim.REQUEST, createClaimSaga),
    takeLatest(actions.cancelClaim.REQUEST, cancelClaimSaga),
    takeLatest(actions.coordinates.REQUEST, coordinatesSaga),
    takeLatest(actions.putPaymentStatus.REQUEST, putPaymentStatusSaga),
    takeLatest(actions.postRepayment.REQUEST, postRepaymentSaga),
    takeLatest(actions.postRefund.REQUEST, postRefundSaga),
    takeLatest(actions.postCancelPayment.REQUEST, postCancelPaymentSaga),
    takeLatest(actions.getAllNetworkEnabled.REQUEST, getAllNetworkEnabledSaga),
    takeLatest(actions.getCartHistory.REQUEST, getCartHistorySaga),
  ]);
}
