import { DeliveryType, OrderStatus, PaymentType } from './types';

export const GET_ORDERS = 'GET_ORDERS';
export const GET_SCHEDULE = 'GET_SCHEDULE';
export const GET_ALL_ORDERS = 'GET_ALL_ORDERS';
export const GET_ORDERS_SEARCH = 'GET_ORDERS_SEARCH';
export const CLEAR_ORDERS_SEARCH = 'CLEAR_ORDERS_SEARCH';
export const CLEAR_ORDERS = 'CLEAR_ORDERS';
export const GET_ORDERS_REFRESH = 'GET_ORDERS_REFRESH';
export const CANCEL_ORDER = 'CANCEL_ORDER';
export const PUT_COLLECTING_STATUS = 'PUT_COLLECTING_STATUS';
export const PUT_READY_STATUS = 'PUT_READY_STATUS';
export const PUT_COMPLETED_STATUS = 'PUT_COMPLETED_STATUS';
export const PUT_CLARIFICATION_STATUS = 'PUT_CLARIFICATION_STATUS';
export const SET_SELECTED_ORDER = 'SET_SELECTED_ORDER';
export const CLEAR_SELECTED_ORDER = 'CLEAR_SELECTED_ORDER';
export const PUT_STATUS = 'PUT_STATUS';
export const SET_ORDER_ID = 'SET_ORDER_ID';
export const CLEAR_ORDER_ID = 'CLEAR_ORDER_ID';
export const PUT_SEEN_ORDERS = 'PUT_SEEN_ORDERS';
export const PUT_MUTED_ORDERS = 'PUT_MUTED_ORDERS';
export const GET_NOT_MUTED_ORDERS = 'GET_NOT_MUTED_ORDERS';
export const GET_NOT_VIEWED_ORDERS = 'GET_NOT_VIEWED_ORDERS';
export const GET_SOURCES_BE_NETWORK = 'GET_SOURCES_BE_NETWORK';
export const GENERATE_TEST_ORDERS = 'GENERATE_TEST_ORDERS';
export const POST_COLLECTOR_MANAGER = 'POST_COLLECTOR_MANAGER';
export const PUT_WAITING_RECEIPT_STATUS = 'PUT_WAITING_RECEIPT_STATUS';
export const PUT_NO_RECEIPT_STATUS = 'PUT_NO_RECEIPT_STATUS';
export const PUT_FOR_ADJUSTMENT_STATUS = 'PUT_FOR_ADJUSTMENT_STATUS';
export const CANCEL_PROMOCODE = 'CANCEL_PROMOCODE';
export const CREATE_CLAIM = 'CREATE_CLAIM';
export const CANCEL_CLAIM = 'CANCEL_CLAIM';
export const COORDINATES = 'COORDINATES';
export const RESET_COORDINATES = 'RESET_COORDINATES';
export const PUT_PAYMENT_STATUS = 'PUT_PAYMENT_STATUS';
export const POST_REPAYMENT = 'POST_REPAYMENT';
export const POST_REFUND = 'POST_REFUND';
export const POST_CANCEL_PAYMENT = 'POST_CANCEL_PAYMENT';
export const GET_ALL_NETWORK_ENABLED = 'GET_ALL_NETWORK_ENABLED';

export const orderDeliveryTypesStrategy = {
  self: DeliveryType.SELF,
  delivery_yandex: DeliveryType.DELIVERY,
};

export const orderPaymentTypesStrategy = {
  kaspi: PaymentType.KASPI_PAY,
  in_place: PaymentType.IN_PLACE,
  interpay: PaymentType.INTERPAY,
};

export const orderStatusesStrategy = {
  new: OrderStatus.UNCONFIRMED,
  waiting_for_receipt: OrderStatus.UNCONFIRMED,
  generate_invoice: OrderStatus.UNCONFIRMED,
  waiting_for_payment: OrderStatus.UNCONFIRMED,
  request_to_place: OrderStatus.UNCONFIRMED,
  in_pharmacy_placed: OrderStatus.UNCONFIRMED,
  in_pharmacy_collecting: OrderStatus.COLLECTING,
  collected: OrderStatus.COLLECTING,
  in_pharmacy_ready: OrderStatus.READY,
  waiting_for_delivery: OrderStatus.READY,
  in_delivery: OrderStatus.READY,
  self_delivery: OrderStatus.READY,
  ready: OrderStatus.READY,
  in_pharmacy_complete: OrderStatus.COMPLETED,
  canceled: OrderStatus.CANCELED,
  request_to_cancel: OrderStatus.CANCELED,
  on_clarification: OrderStatus.CLARIFICATION,
};

export const GET_CART_HISTORY = 'GET_CART_HISTORY';
