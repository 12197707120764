import axios from 'axios';
import { getFromLocalStorage } from '../../global/helpers';

export class SettingsService {
  static API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v2/`;

  static API_BASE_CARD_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v1/`;

  static role = getFromLocalStorage('role');

  static getConfig = async () => {
    const response = await axios({
      method: 'get',
      url: `${SettingsService.API_BASE_URL}admin/system`,
    });
    return response.data;
  };

  static setConfig = async (data: { delivery_enabled: boolean }) => {
    const response = await axios({
      method: 'post',
      url: `${SettingsService.API_BASE_URL}admin/system`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
    return response.data;
  };

  static getCardPayment = async () => {
    const response = await axios({
      method: 'get',
      url: `${SettingsService.API_BASE_CARD_URL}admin/payments/cards`,
    });
    return response.data;
  };

  static setCardPayment = async (data: { enabled: boolean }) => {
    const response = await axios({
      method: 'post',
      url: `${SettingsService.API_BASE_CARD_URL}admin/payments/cards`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
    return response.data;
  };
}
