import axios from 'axios';

export class OrdersService {
  static API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v1/`;

  static API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v2/`;

  static getOrders = async (
    status: string,
    payment?: boolean,
    source?: string,
    paymentType?: string,
    delivery?: string,
    testOrders?: boolean,
    limit?: string
  ) => {
    const lim = limit || '100';
    let url;
    if (testOrders) {
      url = `${OrdersService.API_BASE_URL}orders?test=${testOrders}&offset=0&limit=${lim}`;
    } else if (
      status === '' &&
      !payment &&
      !source &&
      payment !== false &&
      !paymentType &&
      !delivery
    ) {
      url = `${OrdersService.API_BASE_URL}orders?offset=0&limit=${lim}`;
    } else if (status?.length && (payment || payment === false)) {
      url = `${OrdersService.API_BASE_URL}orders?status=${status}&paid=${payment}&offset=0&limit=${lim}`;
    } else if (status?.length && source?.length) {
      url = `${OrdersService.API_BASE_URL}orders?status=${status}&source=${source}&offset=0&limit=${lim}`;
    } else if ((payment || payment === false) && source?.length) {
      url = `${OrdersService.API_BASE_URL}orders?paid=${payment}&source=${source}&offset=0&limit=${lim}`;
    } else if (payment || payment === false) {
      url = `${OrdersService.API_BASE_URL}orders?paid=${payment}&offset=0&limit=${lim}`;
    } else if (source?.length) {
      url = `${OrdersService.API_BASE_URL}orders?source=${source}&offset=0&limit=${lim}`;
    } else if (status?.length) {
      url = `${OrdersService.API_BASE_URL}orders?status=${status}&offset=0&limit=${lim}`;
    } else if (paymentType?.length) {
      url = `${OrdersService.API_BASE_URL}orders?payment_method=${paymentType}&offset=0&limit=${lim}`;
    } else if (delivery?.length) {
      url = `${OrdersService.API_BASE_URL}orders?delivery_method=${delivery}&offset=0&limit=${lim}`;
    }
    const response = await axios({
      method: 'get',
      url,
    });

    return response.data;
  };

  static getAllNetworkEnabled = async () => {
    const url = `https://10.38.132.1:8443/api/network`;

    const response = await axios({
      method: 'get',
      url,
    });

    return response.data;
  };

  static getOrderById = async (id: string, testOrders?: boolean) => {
    const url = testOrders
      ? `${OrdersService.API_BASE_URL}orders/${id}?test=true`
      : `${OrdersService.API_BASE_URL}orders/${id}`;

    const response = await axios({
      method: 'get',
      url,
    });
    return response.data;
  };

  static getSchedule = async (sourceCode: string) => {
    const response = await axios({
      method: 'get',
      url: `${OrdersService.API_BASE_URL}users/schedule/${sourceCode}`,
    });
    return response.data;
  };

  static postCollectorManager = async (
    isCollector: boolean,
    sourceCode: string
  ) => {
    const response = await axios({
      method: 'put',
      url: `${OrdersService.API_BASE_URL}users/collector`,
      data: {
        is_collector: isCollector,
        source_code: sourceCode,
      },
    });
    return response.data;
  };

  static getSourcesByNetwork = async (networkCode: string) => {
    const response = await axios({
      method: 'get',
      url: `${OrdersService.API_BASE_URL}sources?network_code=${networkCode}`,
    });
    return response.data;
  };

  static getAllSources = async () => {
    const response = await axios({
      method: 'get',
      url: `${OrdersService.API_BASE_URL}sources`,
    });
    return response.data;
  };

  static getAllOrders = async (role: string) => {
    const response = await axios({
      method: 'get',
      url: `${OrdersService.API_BASE_URL}orders/${role}`,
    });
    return response.data;
  };

  static getProductsBySkus = async (skus: string[]) => {
    const params = skus.map((sku) => `sku=${sku}`).join('&');
    const response = await axios({
      method: 'get',
      url: `https://prod-backoffice.daribar.com/api/v2/products/cache/get?${params}&attributes=ndda_num,pp_packing,manufacturer_id,recipe_needed,strong_recipe`,
    });
    return response.data;
  };

  static getOrderPaymentsById = async (id: string) => {
    try {
      const url = `${OrdersService.API_BASE_URL}orders/${id}/payments`;
      const response = await axios({
        method: 'get',
        url,
      });
      return response.data;
    } catch (e) {
      return {
        result: [],
      };
    }
  };

  static getOrderSearch = async (searchValue: string, testOrders?: boolean) => {
    const url = testOrders
      ? `${OrdersService.API_BASE_URL}orders/search?q=${searchValue}&test=${testOrders}`
      : `${OrdersService.API_BASE_URL}orders/search?q=${searchValue}`;

    const response = await axios({
      method: 'get',
      url,
    });
    return response.data;
  };

  static changeOrderStatus = async (
    token: string,
    orderId: string,
    status: string,
    cancelMessage?: string
  ) => {
    const response = await axios({
      method: 'put',
      url: `${OrdersService.API_URL}orders/${orderId}/status?status=${status}&msg=${cancelMessage}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res)
      .catch((e) => console.log(e));
    return response?.data;
  };

  static changePaymentStatus = async (
    orderId: string,
    delivery: boolean,
    data: {
      refund_amount?: number;
      status: string;
    }
  ) => {
    const response = await axios({
      method: 'put',
      url: `${OrdersService.API_URL}orders/${orderId}/payment/status?delivery=${delivery}`,
      data,
    });
    return response.data;
  };

  static changePaymentsStatus = async (
    uuid: string,
    data: {
      comment: string;
      status: string;
      type: string;
    }
  ) => {
    const response = await axios({
      method: 'put',
      url: `${OrdersService.API_BASE_URL}orders/payments/${uuid}/status`,
      data,
    });
    return response.data;
  };

  static cancelOrder = async (
    token: string,
    orderId: string,
    cancelMessage?: string
  ) => {
    const response = await axios({
      method: 'put',
      url: `${OrdersService.API_URL}orders/${orderId}/cancel?msg=${cancelMessage}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  static cancelPromocode = async (token: string, orderId: string) => {
    const response = await axios({
      method: 'put',
      url: `${OrdersService.API_BASE_URL}orders/${orderId}/cancel-promocode`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  static getNotViewed = async (testOrders: boolean) => {
    const url = testOrders
      ? `${OrdersService.API_BASE_URL}orders/not_viewed?test_orders=${testOrders}`
      : `${OrdersService.API_BASE_URL}orders/not_viewed`;
    const response = await axios({
      method: 'get',
      url,
    });
    return response.data;
  };

  static getNotMuted = async (testOrders: boolean) => {
    const url = testOrders
      ? `${OrdersService.API_BASE_URL}orders/not_muted?test_orders=${testOrders}`
      : `${OrdersService.API_BASE_URL}orders/not_muted`;
    const response = await axios({
      method: 'get',
      url,
    });
    return response.data;
  };

  static putSeenOrders = async (ordersData: string[]) => {
    const response = await axios({
      method: 'put',
      url: `${OrdersService.API_BASE_URL}orders/viewed`,
      data: {
        orders_ids: ordersData,
      },
    });
    return response.data;
  };

  static createClaim = async (data: any) => {
    const response = await axios({
      method: 'post',
      url: `${OrdersService.API_URL}delivery/claim`,
      data,
    });
    return response.data;
  };

  static coordinates = async (data: any) => {
    const response = await axios({
      method: 'post',
      url: `${OrdersService.API_URL}users/coordinates`,
      data,
    });
    return response.data;
  };

  static cancelClaim = async (claimId: any, data: any) => {
    const response = await axios({
      method: 'put',
      url: `${OrdersService.API_URL}delivery/claim/${claimId}/cancel`,
      data,
    });
    return response.data;
  };

  static putMutedOrders = async (ordersData: string[]) => {
    const response = await axios({
      method: 'put',
      url: `${OrdersService.API_BASE_URL}orders/muted`,
      data: {
        orders_ids: ordersData,
      },
    });
    return response.data;
  };

  static generateTestOrders = async (phone: string, source: string) => {
    const data = source.length
      ? {
          phone,
          source,
        }
      : { phone };
    const response = await axios({
      method: 'post',
      url: `${OrdersService.API_BASE_URL}orders/generate`,
      data,
    });

    return response.data;
  };

  static postRepayment = async (
    id: string,
    data: {
      amount: number;
      comment: string;
      payment_type: string;
    }
  ) => {
    const url = `${OrdersService.API_BASE_URL}orders/${id}/payments`;
    const response = await axios({
      method: 'post',
      url,
      data,
    });
    return response.data;
  };

  static postRefund = async (
    id: string,
    data: {
      amount: number;
      comment: string;
    }
  ) => {
    const url = `${OrdersService.API_BASE_URL}orders/payments/${id}/refunds`;
    const response = await axios({
      method: 'post',
      url,
      data,
    });
    return response.data;
  };

  static postCancelPayment = async (
    id: string,
    data: {
      payment_uuid: string;
      comment: string;
    }
  ) => {
    const url = `${OrdersService.API_BASE_URL}orders/payments/${id}/cancel`;
    const response = await axios({
      method: 'post',
      url,
      data,
    });
    return response.data;
  };

  static getCartHistory = async (orderId: string, createdAt: string) => {
    const response = await axios({
      method: 'get',
      url: `${OrdersService.API_URL}orders/${orderId}/history?from=${createdAt}`,
    });
    return response.data;
  };
}
